import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Selector from './Components/Selector';
import i18nConfig from './i18n/config';
import { Theme } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts.css';
import '@fontsource/roboto/400.css';
import 'moment/locale/nb';
import moment from 'moment/moment';
import { Wrapper } from './Wrapper';

declare global {
  interface Window {
    exp_config: {
      api_url?: string;
      theme?: Theme;
    };
    exp_cart_loaded?: boolean;
    exp_company_code?: string;
    exp_company_id?: number | string;
    dataLayer: any[];
    exp_default_pickup?: string;
    exp_default_dropoff?: string;
    closeCart: () => void;
  }
}
window.exp_config = {
  ...window.exp_config,
  api_url: `${process.env.REACT_APP_BASE_URL}${
    process.env.NODE_ENV === 'development' ? '' : 'api/'
  }v1`
};

const render = (selector: string, component: JSX.Element | JSX.Element[]) => {
  const items = document.querySelectorAll(selector);
  items.forEach((item) => {
    try {
      const element = ReactDOM.createRoot(item);
      element.render(component);
    } catch (err) {
      console.error(err);
    }
  });
};

moment.locale(i18nConfig.resolvedLanguage);

render(
  '.exp-selector',
  <Wrapper>
    <Selector />
  </Wrapper>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
