import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { config } from '../../config';

type LocationSelectorProps = {
  value: string;
  onChange: (value: SelectChangeEvent<string>) => void;
  label: string;
};

export const LocationSelector = ({
  value,
  onChange,
  label
}: LocationSelectorProps) => {
  return (
    <FormControl fullWidth margin={'dense'} variant={'filled'} size={'small'}>
      <InputLabel id={'dropoff-location-label'}>{label}</InputLabel>
      <Select
        labelId="dropoff-location-label"
        id="dropoff-location-select"
        onChange={onChange}
        value={value}
        label={label}
      >
        {config.locations.map((location) => {
          return (
            <MenuItem value={location.id} key={location.id}>
              {location.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
