import moment from 'moment/moment';
import { DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';

type DatePickerProps = {
  value: moment.Moment | null;
  onChange: (value: moment.Moment | null) => void;
  label: string;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  pickerProps?: { [key: string]: any };
};
export const DatePicker = ({
  value,
  onChange,
  label,
  minDate,
  maxDate,
  pickerProps
}: DatePickerProps) => {
  return (
    <DateTimePicker
      label={label}
      disablePast={true}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      value={value}
      slotProps={{
        textField: { variant: 'filled', margin: 'dense', fullWidth: true }
      }}
      viewRenderers={{
        hours: null,
        minutes: null,
        seconds: null
      }}
    />
    // <MUIDatePicker
    //   label={label}
    //   disablePast={true}
    //   minDate={minDate}
    //   maxDate={maxDate}
    //   onChange={onChange}
    //   value={value}
    //   // renderInput={(params) => (
    //   //   <TextInput fullWidth={true} margin={'dense'} {...params} />
    //   // )}
    //   {...pickerProps}
    // />
  );
};
