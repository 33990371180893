import {
  Button,
  Container,
  Grid,
  Modal,
  SelectChangeEvent
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LocationSelector } from './LocationSelector';
import React, { useEffect, useRef, useState } from 'react';
import NextIcon from '@mui/icons-material/NavigateNext';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { toast } from 'react-toastify';
import { DatePicker } from './DatePicker';

// function closeCart() {
//   document
//     .getElementById('exp-cart')
//     .removeChild(document.getElementById('exp-cart').lastChild);
// }
// function openCart() {
//   const iframe = document.createElement('iframe');
//   iframe.style =
//     'position: fixed; border: none; top: 0; left: 0; width: 100vw; height: 100vh';
//   iframe.src =
//     '/?PickupLocationId=119&DropoffLocationId=119&PickupDateTime=2023-06-22 10:00:00&DropoffDateTime=2023-06-23 10:00:00';
//   document.getElementById('exp-cart').appendChild(iframe);
// }

const formatDate = (date: moment.Moment) => {
  return date.format('YYYY-MM-DD HH:mm:ss');
};

const Selector = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const iRef = useRef<HTMLIFrameElement | null>(null);
  const [data, setData] = useState({
    PickupDateTime: formatDate(
      moment().add(1, 'day').hour(10).minutes(0).seconds(0)
    ),
    DropoffDateTime: formatDate(
      moment().add(2, 'days').hour(10).minutes(0).seconds(0)
    ),
    PickupLocationId: window.exp_default_pickup || '',
    DropoffLocationId: window.exp_default_dropoff || ''
  });

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data === 'getConfig' && iRef?.current?.contentWindow) {
        iRef.current.contentWindow.postMessage(
          {
            type: 'config',
            config: window.exp_config,
            exp_company_code: window.exp_company_code || undefined,
            exp_company_id: window.exp_company_id || undefined
          },
          '*'
        );
      }
      if (e.data === 'closeCart') {
        setOpen(false);
      }
    });
  });

  const handlePickupDate = (value: moment.Moment | number | null) => {
    if (value === null || typeof value === 'number') return;
    setData({
      ...data,
      PickupDateTime: formatDate(value),
      DropoffDateTime:
        moment(data.DropoffDateTime).diff(value) < 0
          ? formatDate(value.clone().add(1, 'day'))
          : data.DropoffDateTime
    });
  };
  const handleDropoffDate = (value: moment.Moment | number | null) => {
    if (value === null || typeof value === 'number') return;
    setData({
      ...data,
      DropoffDateTime: formatDate(value)
    });
  };
  const handlePickupLocation = (e: SelectChangeEvent<string>) => {
    setData({
      ...data,
      DropoffLocationId:
        data.DropoffLocationId === '' ||
        data.DropoffLocationId === data.PickupLocationId
          ? e.target.value
          : data.DropoffLocationId,
      PickupLocationId: e.target.value
    });
  };
  const handleDropoffLocation = (e: SelectChangeEvent<string>) => {
    setData({
      ...data,
      DropoffLocationId: e.target.value
    });
  };

  const handleCartOpen = () => {
    if (!data.PickupLocationId) {
      toast.warning(t('Please add Pick-up location to continue'));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'error',
        message: 'Please add Pick-up location to continue'
      });
      return;
    }
    if (!data.DropoffLocationId) {
      toast.warning(t('Please add Drop-off location to continue'));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'error',
        message: 'Please add Drop-off location to continue'
      });
      return;
    }
    // window.location.hash = '/';
    // console.log(formatDate(data.PickupDateTime));
    setOpen(true);
  };

  return (
    <>
      {open && (
        <>
          <Modal open={open}>
            <iframe
              title={'Explorify shopping cart'}
              ref={iRef}
              style={{
                position: 'fixed',
                border: 'none',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                background: 'rgba(255,255,255,0.7)',
                zIndex: 99999999
              }}
              src={`${process.env.REACT_APP_BASE_URL}/cart/?PickupLocationId=${data.PickupLocationId}&DropoffLocationId=${data.DropoffLocationId}&PickupDateTime=${data.PickupDateTime}&DropoffDateTime=${data.DropoffDateTime}`}
            ></iframe>
          </Modal>
        </>
      )}
      <Container maxWidth={'sm'} sx={{ my: 2, minHeight: 350 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LocationSelector
              value={data.PickupLocationId || ''}
              onChange={handlePickupLocation}
              label={t('selector.pickup.location')}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              value={moment(data.PickupDateTime)}
              onChange={handlePickupDate}
              label={t('selector.pickup.date')}
              minDate={moment().add(1, 'day')}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationSelector
              value={data.DropoffLocationId || ''}
              onChange={handleDropoffLocation}
              label={t('selector.dropoff.location')}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              value={moment(data.DropoffDateTime)}
              onChange={handleDropoffDate}
              label={t('selector.dropoff.date')}
              minDate={moment(data.PickupDateTime).add(1, 'day')}
              maxDate={moment(data.PickupDateTime).add(45, 'days')}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              size={'large'}
              onClick={handleCartOpen}
              endIcon={<NextIcon />}
            >
              {t('selector.buttonText')}
            </Button>
          </Grid>
          {(window.exp_company_code || window.exp_company_id) && (
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
              <a
                href={`https://explorify.com?utm_source=${window.location.hostname}&utm_medium=partner_widget&utm_campaign=partner_widget`}
                style={{ color: 'inherit', textDecoration: 'none' }}
                target={'_blank'}
                rel="noreferrer"
              >
                Motorcycle Rentals powered by &nbsp;
                <Logo
                  style={{ width: 70 }}
                  role={'img'}
                  title={'Explorify Motorcycle Rentals'}
                />
              </a>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Selector;
