export const config = {
  locations: [
    {
      id: 123,
      name: 'Chicago Harley-Davidson OHare Airport Illinois USA'
    },
    // {
    //   id: 119,
    //   name: 'Denver Colorado USA'
    // },
    {
      id: 124,
      name: 'Las Vegas Nevada USA'
    },
    {
      id: 120,
      name: 'Los Angeles Airport LAX California USA'
    },
    // {
    //   id: 151,
    //   name: 'New Orleans Louisiana USA'
    // },
    { id: 162, name: 'New Jersey  New York' },
    {
      id: 116,
      name: 'Orlando Florida USA'
    }
  ]
};
