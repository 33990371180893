import React from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import i18nConfig from './i18n/config';
import { deepmerge } from '@mui/utils';
import { ToastContainer } from 'react-toastify';

type WrapperProps = {
  children: JSX.Element | JSX.Element[];
};

const htmlTag = document.getElementsByTagName('html')[0];
const htmlFontSize: number =
  parseInt(
    window
      .getComputedStyle(htmlTag, null)
      .getPropertyValue('font-size')
      .replace(/[^0-9.]/g, '')
  ) || 16;
const theme = createTheme(
  deepmerge(
    {
      palette: {
        primary: {
          main: '#f47923',
          contrastText: '#fff'
        }
      },
      typography: {
        htmlFontSize: htmlFontSize,
        body2: {
          fontSize: 14
        },
        h1: {
          fontSize: 36,
          fontFamily: '"Oswald", sans-serif',
          marginBottom: 20
        },
        h2: {
          fontSize: 28,
          fontFamily: '"Oswald", sans-serif',
          marginBottom: 15
        },
        h3: {
          fontSize: 24,
          fontFamily: '"Oswald", sans-serif',
          marginBottom: 15
        },
        h4: {
          fontSize: 20,
          fontFamily: '"Oswald", sans-serif',
          marginBottom: 15
        },
        h5: {
          fontSize: 20,
          fontFamily: '"Oswald", sans-serif'
        },
        h6: {
          fontSize: 18,
          fontFamily: '"Oswald", sans-serif'
        },
        button: {
          fontFamily: '"Oswald", sans-serif',
          fontWeight: 600
        }
      },
      components: {
        MuiInputBase: {
          styleOverrides: {
            input: {
              lineHeight: 'inherit !important',
              height: 'auto !important',
              margin: '0 !important',
              padding: '21px 12px 4px 12px !important'
            }
          }
        },
        MuiAutocomplete: {
          styleOverrides: {
            inputRoot: {
              paddingTop: '0 !important',
              paddingLeft: '0 !important'
            },
            root: {}
          }
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: '#000'
            }
          }
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              'background': 'rgba(127,127,127,0.3)',
              'color': '#000',
              '&.Mui-focused': {
                background: 'rgba(127,127,127,0.35)',
                color: '#000'
              },
              '&:hover': {
                background: 'rgba(127,127,127,0.35)',
                color: '#000'
              }
            }
          }
        }
      }
    },
    window?.exp_config?.theme || {}
  )
);
export const Wrapper = ({ children }: WrapperProps) => {
  const path = window.location.pathname;
  return (
    <React.StrictMode>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={i18nConfig.resolvedLanguage}
        >
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
